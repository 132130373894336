@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500;700&family=Lexend:wght@500;700&display=swap);
#root {
    /* Color/gradient variables defined*/
    --dark-gradient: linear-gradient(45deg, rgba(17, 35, 50, 1), rgba(7, 37, 62, 1));
    --bg-color: #112332;
    --fluid-white-gradient: linear-gradient(-45deg, #f1f4fd, #e2ebf0);
    --light-gradient: linear-gradient(90deg, #ff5f6d, #ffc371);
    --gray-gradient: linear-gradient(45deg, #0f2027, #203a43);
    --fluid-gray-gradient: linear-gradient(-45deg, #0f2027, #19313b, #234452, #2c5364);
    --gray-base: #234452;
    /*CSS Properties begin*/
    background: var(--fluid-white-gradient);
    font-family: "Inter";
    font-weight: 500;
    font-size: 18px;
    color: #000;
    align-items: right;
    font-display: swap;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;

    /* Addresses a small issue in webkit: https://bit.ly/NEdoDq */
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

p {
    margin-bottom: 0 !important;
}

sm {
    font-size: 12px;
}

.header {
    background: var(--gray-gradient);
    padding-top: 10vw;
    padding-bottom: 10vw;
    text-align: center;
    min-height: calc(100vh - 118px);
    width: 100%;
}

.no-js .header {
    background: url(/static/media/cocoa-beach-me.625a48c3.jpg) no-repeat center top;
    background-attachment: relative;
    background-position: center center;
    background-size: cover;
}

.webp .header {
    background: url(/static/media/cocoa-beach-me.625a48c3.webp) no-repeat center top;
    background-attachment: relative;
    background-position: center center;
    background-size: cover;
}
.no-webp .header {
    background: url(/static/media/cocoa-beach-me.625a48c3.jpg) no-repeat center top;
    background-attachment: relative;
    background-position: center center;
    background-size: cover;
}

.outer-structure {
    display: flex;
    min-height: calc(100vh - 118px);
}

.inner-structure {
    display: flex;
    min-height: calc(100vh - 264px);
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header h1 {
    font-size: 80px;
    color: white;
    background: rgba(0, 0, 0, 0.5);
}

.header h3 {
    font-size: 20px;
    color: white;
}

@media (min-aspect-ratio: 1/2) {
    #heading-name {
        font-size: 6.5vw;
        text-shadow: 2px 2px 4px #000000;
        border-radius: 20px;
    }

    #heading-desc {
        font-size: 3.5vw;
        text-shadow: 2px 2px 4px #000000;
    }

    .header-img {
        min-width: 10em;
        width: 10em;
        height: auto;
        border-radius: 50%;
        border: 5px solid rgba(17, 35, 50, 1);
        position: static;
    }
}

@media (max-aspect-ratio: 1/2) {
    #heading-name {
        font-size: 1.75em;
        text-shadow: 0.75px 0.75px #000000;
        border-radius: 10px;
    }

    #heading-desc {
        font-size: 18px;
        text-shadow: 0.75px 0.75px #000000;
    }

    .header-img {
        max-width: 55%;
        min-width: 10em;
        width: 10em;
        height: auto;
        padding: 15px;
        border-radius: 50%;
        border: 5px solid rgba(17, 35, 50, 1);
        position: static;
    }

    .flip-card {
        min-height: 22.5em;
    }
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
    background-color: transparent;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 90vw;
    min-width: 30vw;
    width: 450px;
    height: 350px;
    font-size: 16px;
    padding: 0.75rem;
    -webkit-perspective: 1000px;
            perspective: 1000px; /*Remove this if you don't want the 3D effect*/
}

/* This container is needed to position the front and back side */
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    color: white;
}
.primary .flip-card-front {
    background: var(--fluid-gray-gradient);
    background-size: 400% 400%;
    -webkit-animation: gradient 3s ease infinite;
            animation: gradient 3s ease infinite;
    box-shadow: 2px 2px 5px 2px #000000;
}

/* Style the back side */
.flip-card-back {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    color: white;
}
.primary .flip-card-back {
    background: var(--fluid-gray-gradient);
    background-size: 400% 400%;
    -webkit-animation: gradient 3s ease infinite;
            animation: gradient 3s ease infinite;
    box-shadow: 2px 2px 5px 2px #000000;
}

.flip-card-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 1rem 0rem;
}

.primary t {
    /* color: white; */
    font-weight: 700;
}

.primary imp {
    padding: 6px;
    color: white;
    background: transparent;
}

.primary more {
    color: #616669;
}

.title-bar {
    font-family: "Lexend";
    color: white;
    margin: 0;
    padding: 1.5em 0;
    width: 100%;
}

.secondary-text {
    font-family: "Lexend";
}

.dark-bar {
    background: var(--dark-gradient);
}

#basic-navbar-nav > * > * {
    margin-left: auto;
    margin-right: auto;
}

.resume-btn {
    background: var(--fluid-gray-gradient);
    background-size: 400% 400%;
    color: white !important;
    border: 0.15em solid var(--gray-base) !important;
    border-radius: 20px !important;
    padding: 15px 30px !important;
    margin: 0.5em;
    -webkit-animation: gradient 3s ease infinite;
            animation: gradient 3s ease infinite;
}

.resume-btn.modified {
    padding: 10px 10px !important;
}

.resume-btn:hover {
    background: transparent !important;
    color: var(--gray-base) !important;
}

.gradient-text {
    background-color: #ff5f6d;
    background: var(--light-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

@-webkit-keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

